import { yupResolver } from '@hookform/resolvers/yup';
import { Badge, Button, FileInput, Label, Modal, Select, Spinner, Table } from 'flowbite-react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { store } from 'redux/store';
import { ScriptHistories } from '../../_interfaces';
import { AuthToken } from '../../_interfaces/usertoken.interfaces';
import { ApiErrorResponse } from '../../_network/response';
import { SuperadminServices } from '../../_services';
import { uploadServices } from '../../_services/upload.services';
import { UserTokenServices } from '../../_services/usertoken.services';
import { useFetch } from '../../hooks/useFetch';

const CreateWageRequestPage = () => {
  const redux_state = store.getState();

  const [page] = useState(1);
  const getHistory = useFetch<ScriptHistories>(SuperadminServices.Script.Script_GetByPagination);
  const getUserToken = useFetch<AuthToken>(UserTokenServices.ValidateToken);

  useEffect(() => {
    getUserToken.request(redux_state.auth.token ?? '');
  }, []);
  useEffect(() => {
    getHistory.request('CREATE_WAGE_REQUEST', page, 100);
  }, [page]);
  // 2. Hook for Updater
  const [create_modal, set_create_modal] = useState(false);
  const [output_modal, set_output_modal] = useState<{ show: boolean; id: number }>({ show: false, id: 0 });

  const allow_create_wage_request = () => {
    const allow_list: string[] = process.env.REACT_APP_WAGE_REQUEST_SCRIPT?.split('|') ?? [];
    return allow_list.filter((l) => l === getUserToken.data?.employeeCode.toString()).length > 0;
  };

  if (!allow_create_wage_request()) {
    return (
      <>
        <h2 className="text-xl">You don't have permission for this page</h2>
      </>
    );
  }

  // https://flowbite.com/docs/components/tabs/
  return (
    <>
      {create_modal && (
        <CreateModal
          onSuccess={() => getHistory.request('CREATE_WAGE_REQUEST', page, 100)}
          onClose={() => set_create_modal(false)}
        />
      )}
      {output_modal.show && (
        <OutputModal id={output_modal.id} onClose={() => set_output_modal({ show: false, id: 0 })} />
      )}

      <div className="mb-8 flex justify-end">
        <Button type="button" onClick={() => set_create_modal(true)} size={'sm'}>
          Create Script
        </Button>
      </div>
      <Table>
        <Table.Head>
          <Table.Cell>ID</Table.Cell>
          <Table.Cell>Tanggal</Table.Cell>
          <Table.Cell>Param</Table.Cell>
          <Table.Cell>Status</Table.Cell>
          <Table.Cell />
        </Table.Head>
        <Table.Body>
          {getHistory.loading && (
            <Table.Row>
              <Table.Cell colSpan={5}>
                <Spinner />
              </Table.Cell>
            </Table.Row>
          )}
          {getHistory.data?.list.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={5}>Belum ada data</Table.Cell>
            </Table.Row>
          )}
          {getHistory.data?.list.map((h) => (
            <Table.Row key={`wr.history.${h.id}`}>
              <Table.Cell>#{h.id}</Table.Cell>
              <Table.Cell className="whitespace-nowrap">{moment(h.created_at).format('DD-MM-YYYY HH:mm')}</Table.Cell>
              <Table.Cell>{h.param}</Table.Cell>
              <Table.Cell>
                <Badge
                  color={
                    h.status === 'DONE'
                      ? 'success'
                      : h.status === 'ERROR' || h.status === 'CANCEL'
                      ? 'failure'
                      : 'warning'
                  }
                  className="capitalize"
                >
                  {h.status.toLowerCase()}
                </Badge>
              </Table.Cell>
              <Table.Cell>
                <Button type="button" onClick={() => set_output_modal({ show: true, id: h.id })} size={'xs'}>
                  {' '}
                  Output
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default CreateWageRequestPage;

const CreateModal = (props: { onSuccess: () => void; onClose: () => void }) => {
  const upload_file_ref = useRef<HTMLInputElement>(null);
  const [upload_file, set_upload_file] = useState<any>();
  const ValidationSchema = yup.object().shape({
    environment: yup.string().oneOf(['staging', 'production']).required(),
    file: yup.string(),
  });

  const [submitting, setSubmitting] = useState(false);
  const [, set_upload_progress] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<{ environment: 'staging' | 'production'; file: string }>({
    mode: 'onChange',
    resolver: yupResolver(ValidationSchema),
    defaultValues: { environment: 'production' },
  });

  const onSubmitHandler = async (data: { environment: 'staging' | 'production'; file: string }) => {
    try {
      setSubmitting(false);

      if (upload_file !== undefined) {
        if (upload_file.type !== 'text/csv') {
          toast.error('invalid image type, required: csv');
          setError('file', { message: 'invalid image type, required: csv' });
          return;
        } else if (upload_file.size > 1024 * 1024 * 5) {
          toast.error('maximum image: 5MB');
          setError('file', { message: 'maximum image: 5MB' });
          return;
        }
        set_upload_progress(0);

        const uploadImage = await uploadServices.uploadImage(upload_file, 'superadmin-script/create-wage-request');
        if (uploadImage.data.url) {
          data.file = uploadImage.data.url;
        }
      }

      if (data.file === '') {
        setError('file', { message: 'file is required' }, { shouldFocus: true });
        return;
      }

      await SuperadminServices.Script.Script_CreateWageRequest(data.environment, data.file);
      toast.success('Script run');
      props.onClose();
      props.onSuccess();
    } catch (error) {
      if (error as ApiErrorResponse) {
        toast.error((error as ApiErrorResponse).message);
      } else {
        console.log('Unknown error:', error);
        toast.error('Internal Error');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal show={true} onClose={() => props.onClose()}>
      <Modal.Header>Run Create Wage Request Script</Modal.Header>
      <Modal.Body>
        <form key="create-form" className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)}>
          <div id="select">
            <div className="mb-2 block">
              <Label htmlFor="environment" value="Environment" />
            </div>
            <Select {...register('environment')} id="environment" required={true}>
              <option value={'production'}> Production</option>
              <option value={'staging'}> Staging </option>
            </Select>
          </div>
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">
            {errors?.environment && <>{errors.environment.message}</>}
          </p>

          <FileInput
            ref={upload_file_ref}
            accept="text/csv"
            onChange={(e) => {
              if (e.target.files) {
                if (e.target.files.length > 0) {
                  set_upload_file(e.target.files[0]);
                } else {
                  set_upload_file(undefined);
                }
              }
            }}
          />
          <p className="text-xs">
            Create Wage Request csv file. <br />
            Download Template: &nbsp;
            <a
              href="https://storage.googleapis.com/gajiku-jenkins/upload-to-create-wage-request-template.csv"
              target="_blank"
              rel="noreferrer"
              className="hover:underline"
            >
              https://storage.googleapis.com/gajiku-jenkins/upload-to-create-wage-request-template.csv
            </a>
          </p>
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors?.file && <>{errors.file.message}</>}</p>

          <div className="flex items-center justify-center">
            <Button type="submit" disabled={submitting}>
              Submit {submitting && <Spinner />}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

const OutputModal = (props: { id: number; onClose: () => void }) => {
  const getOutput = useFetch<{ output: string }>(SuperadminServices.Script.Script_GetOutput);
  useEffect(() => {
    getOutput.request(props.id);
  }, []);

  return (
    <>
      <Modal
        size={'7xl'}
        show={true}
        onClose={() => {
          props.onClose();
        }}
      >
        <Modal.Header>Output Console</Modal.Header>
        <Modal.Body>
          <pre className="overflow-auto text-xs">
            {getOutput.data === undefined ? (
              <>
                <Spinner />
              </>
            ) : (
              <code>{getOutput.data.output}</code>
            )}
          </pre>
        </Modal.Body>
      </Modal>
    </>
  );
};
