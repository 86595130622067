import { Avatar, Button, FileInput, Label, Modal, Spinner, Textarea } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ApiErrorResponse } from '../../../../_network/response';
import { uploadServices } from '../../../../_services/upload.services';
import defaultImage from '../../../../assets/images/defaultImage.png';
import useUpload from '../../../../hooks/useUpload';
import useDigitalProduct from '../../../../pages/digital-product/useDigitalProduct';

export const TncModal = ({
  show,
  code,
  onClose,
}: {
  show: boolean;
  code: string;
  onClose: (show: boolean) => void;
}) => {
  const { FindDigitalProduct, digitalProductDetail, UpdateDigitalProduct } = useDigitalProduct();
  const [description, setDescriptions] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const [fileUrl] = useUpload();
  const [, setUrlVoucherImage] = useState<string>('');
  const [urlFile, setUrlFile] = useState<any>();
  const [uploadFile, setUploadFile] = useState<any>();
  useEffect(() => {
    if (code) FindDigitalProduct(code);
  }, [code]);

  useEffect(() => {
    if (digitalProductDetail) {
      setDescriptions(digitalProductDetail?.detail);
      setUrlFile(digitalProductDetail?.voucherImage ?? '');
    }
  }, [digitalProductDetail]);

  useEffect(() => {
    setUploadFile(null);
  }, []);

  const handleUploadFile = (files: FileList | null) => {
    if (files) {
      const urlFile = URL.createObjectURL(files[0]);
      setUrlFile(urlFile);
      setUploadFile(files[0]);
    }
  };

  useEffect(() => {
    if (fileUrl) {
      setUrlVoucherImage(fileUrl);
    }
  }, [fileUrl]);

  const handleSubmit = async () => {
    // let promises: Array<Promise<any>> = [];
    setLoading(true);
    // if(uploadFile) {
    //   promises.push(uploadServices.uploadImage(uploadFile, 'superadmin/voucher'))
    // }

    let obj = {
      id: digitalProductDetail?.id ?? 0,
      price: digitalProductDetail?.price ?? 0,
      discount: digitalProductDetail?.discount ?? 0,
      fee: digitalProductDetail?.fee ?? 0,
      isActive: digitalProductDetail?.isActive ?? false,
      detail: description ?? '',
      voucherImage: digitalProductDetail?.voucherImage ?? '',
    };

    if (uploadFile) {
      try {
        setLoadingUpload(true);
        const uplaodImage = await uploadServices.uploadImage(uploadFile, 'superadmin/voucher');
        obj.voucherImage = uplaodImage.data.url;
      } catch (error) {
        toast.error((error as ApiErrorResponse).message);
      } finally {
        setLoadingUpload(false);
      }
    }
    await UpdateDigitalProduct(obj);
    setLoading(false);
    onClose(true);
  };

  return (
    <>
      <Modal show={show} onClose={() => onClose(false)}>
        <Modal.Header>Terms and Conditions</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <table className="w-full">
              <tr>
                <td className="py-2">
                  <Label>Product Code</Label>
                </td>
                <td className="py-2">{digitalProductDetail?.code}</td>
              </tr>
              <tr>
                <td className="py-2">
                  <Label>Name</Label>
                </td>
                <td className="py-2">{digitalProductDetail?.name}</td>
              </tr>
            </table>
            <div className="mb-2 !mt-2">
              <Label htmlFor="description" value="Descriptions" />
            </div>
            <Textarea
              id="description"
              placeholder="Type the descriptions"
              required={true}
              rows={4}
              value={description}
              onChange={(e) => setDescriptions(e.target.value)}
            />
          </div>
          <div className="mb-2 !mt-4">
            <div className="mb-2">
              <Label>Voucher Image</Label>
            </div>
            <div>
              <div className="preview-logo mb-4">
                <Avatar img={urlFile !== '' ? urlFile : defaultImage} size="xl" />
              </div>
              <FileInput
                id="fileUpload"
                onChange={async (e) => {
                  handleUploadFile(e.target.files);
                  e.target.value = '';
                  // setFormBackendError({ ...formBackendError, photo: '' });
                }}
                helperText={
                  loadingUpload ? (
                    <>
                      <Spinner /> Uploading....
                    </>
                  ) : (
                    'Maximum size 10mb'
                  )
                }
              />
            </div>
          </div>
          <div className="flex gap-2 justify-center my-2">
            <Button
              color={'info'}
              disabled={loading || loadingUpload}
              onClick={() => {
                handleSubmit();
              }}
            >
              {loading && (
                <div className="mr-2">
                  {' '}
                  <Spinner />{' '}
                </div>
              )}
              Save
            </Button>
            <Button
              color="failure"
              onClick={() => {
                onClose(false);
                setUrlFile('');
                setDescriptions('');
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
