import { yupResolver } from '@hookform/resolvers/yup';
import { Badge, Button, Label, Modal, Spinner, Table, TextInput } from 'flowbite-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { ScriptHistories } from '../../_interfaces';
import { ApiErrorResponse } from '../../_network/response';
import { SuperadminServices } from '../../_services';
import { useFetch } from '../../hooks/useFetch';

const ScriptResetPinPage = () => {
  const [page] = useState(1);
  const getHistory = useFetch<ScriptHistories>(SuperadminServices.Script.Script_GetByPagination);
  useEffect(() => {
    getHistory.request('RESET_PIN', page, 500);
  }, [page]);
  // 2. Hook for Updater
  const [create_modal, set_create_modal] = useState(false);
  const [output_modal, set_output_modal] = useState<{ show: boolean; id: number }>({ show: false, id: 0 });

  // https://flowbite.com/docs/components/tabs/
  return (
    <>
      {create_modal && (
        <CreateModal
          onSuccess={() => getHistory.request('RESET_PIN', page, 100)}
          onClose={() => set_create_modal(false)}
        />
      )}
      {output_modal.show && (
        <OutputModal id={output_modal.id} onClose={() => set_output_modal({ show: false, id: 0 })} />
      )}

      <div className="mb-8 flex justify-end">
        <Button type="button" onClick={() => set_create_modal(true)} size={'sm'}>
          Create Script
        </Button>
      </div>
      <Table>
        <Table.Head>
          <Table.Cell>ID</Table.Cell>
          <Table.Cell>Tanggal</Table.Cell>
          <Table.Cell>Param</Table.Cell>
          <Table.Cell>Status</Table.Cell>
          <Table.Cell />
        </Table.Head>
        <Table.Body>
          {getHistory.loading && (
            <Table.Row>
              <Table.Cell colSpan={5}>
                <Spinner />
              </Table.Cell>
            </Table.Row>
          )}
          {getHistory.data?.list.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={5}>Belum ada data</Table.Cell>
            </Table.Row>
          )}
          {getHistory.data?.list.map((h) => (
            <Table.Row key={`script.reset-pin.${h.id}`}>
              <Table.Cell>#{h.id}</Table.Cell>
              <Table.Cell className="whitespace-nowrap">{moment(h.created_at).format('DD-MM-YYYY HH:mm')}</Table.Cell>
              <Table.Cell>{h.param}</Table.Cell>
              <Table.Cell>
                <Badge
                  color={
                    h.status === 'DONE'
                      ? 'success'
                      : h.status === 'ERROR' || h.status === 'CANCEL'
                      ? 'failure'
                      : 'warning'
                  }
                  className="capitalize"
                >
                  {h.status.toLowerCase()}
                </Badge>
              </Table.Cell>
              <Table.Cell>
                <Button type="button" onClick={() => set_output_modal({ show: true, id: h.id })} size={'xs'}>
                  {' '}
                  Output
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default ScriptResetPinPage;

const CreateModal = (props: { onSuccess: () => void; onClose: () => void }) => {
  const ValidationSchema = yup.object().shape({
    companyId: yup.string().required(),
    employeeNumber: yup.string().required(),
  });

  const [submitting, setSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ companyId: string; employeeNumber: string }>({
    mode: 'onChange',
    resolver: yupResolver(ValidationSchema),
    defaultValues: {
      companyId: '',
      employeeNumber: '',
    },
  });

  const onSubmitHandler = async (data: { companyId: string; employeeNumber: string }) => {
    try {
      setSubmitting(false);

      await SuperadminServices.Script.Script_ResetPin(data.companyId, data.employeeNumber);
      toast.success('Script run');
      props.onClose();
      props.onSuccess();
    } catch (error) {
      if (error as ApiErrorResponse) {
        toast.error((error as ApiErrorResponse).message);
      } else {
        console.log('Unknown error:', error);
        toast.error('Internal Error');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        show={true}
        onClose={() => {
          props.onClose();
        }}
      >
        <Modal.Header>Run Reset PIN</Modal.Header>
        <Modal.Body>
          <form key="create-form" className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)}>
            <div>
              <div className="mb-2 block">
                {' '}
                <Label htmlFor="companyId" value="Company ID" />
              </div>
              <TextInput {...register('companyId')} id="companyId" />
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                {errors?.companyId && <>{errors.companyId.message}</>}
              </p>
              <p className="text-xs">
                Production Company Id List
                <br />
                Plataran: 3mN6vkjl7q
                <br />
                Gigacover C1: qmW6wljPzG
                <br />
                Gigacover C2: 5KYaXz61OM
                <br />
                Gigacover H1: QRn6yzGj5o
                <br />
                Gigacover H3: 9QZxRLNjL4
                <br />
                Gigacover I: PVna0gljdX
                <br />
              </p>
            </div>
            <div>
              <div className="mb-2 block">
                {' '}
                <Label htmlFor="employeeNumber" value="Employee Number" />
              </div>
              <TextInput {...register('employeeNumber')} id="employeeNumber" />
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                {errors?.employeeNumber && <>{errors.employeeNumber.message}</>}
              </p>
            </div>

            <div className="flex items-center justify-center">
              <Button type="submit" disabled={submitting}>
                Submit {submitting && <Spinner />}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const OutputModal = (props: { id: number; onClose: () => void }) => {
  const getOutput = useFetch<{ output: string }>(SuperadminServices.Script.Script_GetOutput);
  useEffect(() => {
    getOutput.request(props.id);
  }, []);

  return (
    <>
      <Modal
        size={'7xl'}
        show={true}
        onClose={() => {
          props.onClose();
        }}
      >
        <Modal.Header>Output Console</Modal.Header>
        <Modal.Body>
          <pre className="overflow-auto text-xs">
            {getOutput.data === undefined ? (
              <>
                <Spinner />
              </>
            ) : (
              <code>{getOutput.data.output}</code>
            )}
          </pre>
        </Modal.Body>
      </Modal>
    </>
  );
};
